import React from "react"
import styled, { css } from "styled-components"
import { Image } from "./image.component"
import { Annotation } from "./general-styles.component"
import { useStaticQuery, graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { AppFunctionComponent } from "../../types"

const AuthorInfo = styled.div<{ hasDescription: boolean }>`
  display: grid;
  grid-row-gap: 6px;
  grid-column-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 50px 1fr;
  grid-template-areas: "Avatar Name";

  p {
    grid-area: Name;
    line-height: 1;
    align-self: center;
    font-size: ${15 / 16}rem;
    font-family: ${({ theme }) => theme.font.display};
  }

  ${Annotation} {
    grid-area: Description;
    line-height: 1;
    font-size: ${14 / 16}rem;
    font-family: ${({ theme }) => theme.font.basic};
  }

  ${({ hasDescription }) =>
    hasDescription &&
    css`
      grid-template-rows: auto auto;
      grid-template-areas: "Avatar Name" "Avatar Description";

      p {
        align-self: end;
      }
      ${Annotation} {
        align-self: start;
      }
    `};
`

const AuthorAvatar = styled(Image)`
  grid-area: Avatar;

  img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
  }
`

interface Props {
  name: string
  avatar?: IGatsbyImageData | null
  description?: string | null
  className?: string
}

const Author: AppFunctionComponent<Props> = ({
  className,
  avatar = null,
  name,
  description = null,
}) => {
  const data = useStaticQuery(graphql`
    query GuestPlaceholderImage {
      file(name: { eq: "guest-placeholder" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            height: 50
          )
        }
      }
    }
  `)

  const guestPlaceholderImage = data?.file.childImageSharp.gatsbyImageData

  return (
    <AuthorInfo className={className} hasDescription={Boolean(description)}>
      <AuthorAvatar image={avatar || guestPlaceholderImage} />
      <p>{name}</p>
      {description && <Annotation>{description}</Annotation>}
    </AuthorInfo>
  )
}

export default styled(Author)``
