import React from "react"
import { AppFunctionComponent } from "../../../types"
import { NeutralTextLink } from "../../../ui/components/link/link.component"
import facebookIcon from "../../../images/icons/fb.svg"
import linkedInIcon from "../../../images/icons/in.svg"
import instagramIcon from "../../../images/icons/insta.svg"
import pinterestIcon from "../../../images/icons/pin.svg"
import twitterIcon from "../../../images/icons/tt.svg"
import { SocialIcon } from "./social-media-share.styled"
import { SocialMediaLink } from "./social-media-share.types"

const SocialMediaShare: AppFunctionComponent<SocialMediaLink> = ({
  slug,
  title,
}) => {
  const encodedPostLink = encodeURIComponent(`https://mudita.com/media/${slug}`)
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedPostLink}`
  const linkedInLink = `http://www.linkedin.com/shareArticle?mini=true&url=${encodedPostLink}&title=${title}`
  const twitterLink = `http://twitter.com/intent/tweet?url=${encodedPostLink}`
  const pinterestLink = `https://pinterest.com/pin/create/button/?url=${encodedPostLink}`
  const instagramLink = `https://www.instagram.com/wearemudita/?hl=en`

  const sharePost = (link: string, size: string) => {
    window.open(link, "newwindow", size)
  }

  const facebookShare = (e: MouseEvent) => {
    e.preventDefault()
    sharePost(facebookLink, "width=300,height=250")
  }

  const twitterShare = (e: MouseEvent) => {
    e.preventDefault()
    sharePost(twitterLink, "width=600,height=300")
  }

  const linkedInShare = (e: MouseEvent) => {
    e.preventDefault()
    sharePost(linkedInLink, "width=600,height=600")
  }

  const pinterestShare = (e: MouseEvent) => {
    e.preventDefault()
    sharePost(pinterestLink, "width=600,height=600")
  }

  const instagramShare = (e: MouseEvent) => {
    e.preventDefault()
    sharePost(instagramLink, "width=600,height=600")
  }

  return (
    <>
      <NeutralTextLink onClick={facebookShare} to={facebookLink}>
        <SocialIcon src={facebookIcon} alt={"Share on Facebook"} />
      </NeutralTextLink>
      <NeutralTextLink onClick={twitterShare} to={twitterLink}>
        <SocialIcon src={twitterIcon} alt={"Share on Twitter"} />
      </NeutralTextLink>
      <NeutralTextLink onClick={linkedInShare} to={linkedInLink}>
        <SocialIcon src={linkedInIcon} alt={"Share on LinkedIn"} />
      </NeutralTextLink>
      <NeutralTextLink onClick={pinterestShare} to={pinterestLink}>
        <SocialIcon src={pinterestIcon} alt={"Share on Pinterest"} />
      </NeutralTextLink>
      <NeutralTextLink onClick={instagramShare} to={instagramLink}>
        <SocialIcon src={instagramIcon} alt={"Share on Instagram"} />
      </NeutralTextLink>
    </>
  )
}

export default SocialMediaShare
