import React from "react"
import { useLocation } from "react-use"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { buttonStyles } from "../../../ui/components/button/button.styled"
import {
  BasicButtonLink,
  NeutralRawLink,
} from "../../../ui/components/link/link.component"
import { useCreateThread } from "../../common/community-comments-count/use-create-thread.hook"
import { ErrorMessage } from "../../common/general-styles.component"
import { SecondaryHeading } from "../../common/heading.component"
import Section from "../../common/sections/section.component"
import BlogComment from "./blog-comment.component"
import { Comment } from "./blog.interface"

interface Props {
  postId: number
  comments: Comment[]
  count: number
  title: string
  contentfulId: string
  category?: number
}

const BlogCommentsContainer = styled(Section)`
  position: relative;
  ${SecondaryHeading} {
    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
      padding-bottom: 20px;
    }
    padding-bottom: 10px;
    span {
      font-weight: 400;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    padding: 0 100px;
  }
`

const BlogCommentButton = styled(BasicButtonLink)`
  margin-top: -10px;
  float: right;
`
const NewThreadButton = styled.button`
  margin-top: -10px;
  float: right;
  ${buttonStyles};
`

const BlogCommentsBottomSection = styled.div`
  padding-top: 20px;
`

const FindMore = styled.div`
  font-family: Facto;
  font-size: ${22 / 16}rem;
  margin-top: 50px;

  ${NeutralRawLink} {
    color: ${({ theme }) => theme.color.link};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    margin-top: 90px;
  }
`

const BlogComments: AppFunctionComponent<Props> = ({
  postId,
  comments,
  count,
  title,
  contentfulId,
  category,
}) => {
  const discourseUrl = `${process.env.GATSBY_COMMUNITY_URL}/t/${postId}`
  const { createThread, error, pending } = useCreateThread()
  const url = useLocation().href

  const data = {
    title,
    category,
    blogPostUrl: url,
    blogPostId: contentfulId,
  }

  return (
    <BlogCommentsContainer>
      <SecondaryHeading renderAs={"div"}>
        Comments <span>({count})</span>
        {comments.length > 0 && (
          <BlogCommentButton to={discourseUrl} target={"_blank"}>
            Comment
          </BlogCommentButton>
        )}
      </SecondaryHeading>
      {!comments ? "Loading comments please wait ..." : ""}
      {comments.length > 0 ? (
        comments.map((comment: Comment) => {
          const { id, reply_to_user } = comment
          return (
            <BlogComment key={id} comment={comment} reply={reply_to_user} />
          )
        })
      ) : (
        <span>
          No comments here. Be the first to comment{" "}
          <NewThreadButton onClick={() => createThread(data)}>
            {pending ? "Creating thread..." : "Start thread"}
          </NewThreadButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </span>
      )}
      <BlogCommentsBottomSection>
        {comments.length > 0 && (
          <BlogCommentButton to={discourseUrl} target={"_blank"}>
            Comment
          </BlogCommentButton>
        )}
      </BlogCommentsBottomSection>
      <FindMore>
        Find more topics on{" "}
        <NeutralRawLink to="https://forum.mudita.com/">
          our forum →
        </NeutralRawLink>
      </FindMore>
    </BlogCommentsContainer>
  )
}

export default BlogComments
