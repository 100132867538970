import { useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import {
  Method,
  useNetwork,
} from "../../../internal-api/network/use-network.hook"
import { NETLIFY_URL } from "../../../internal-api/commerce/constants"

interface Data {
  title: string
  category?: number
  blogPostUrl?: string
  blogPostId: string
}

const config = {
  baseURL: NETLIFY_URL,
}

export const useCreateThread = () => {
  const [error, setError] = useState("")

  const [threadCreated, fetch] = useNetwork<Response>(
    {
      url: "/create-forum-topic",
      method: Method.Post,
    },
    config
  )

  const { loading: pending } = threadCreated

  const createThread = async (data: Data) => {
    return await fetch({ data })
      .then((response: AxiosResponse) => {
        response.data && (window.location.href = response.data.message)
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 422) {
          setError("This thread has already been created by another user.")
        } else {
          setError("Something went wrong. Try again.")
        }
      })
  }

  return { error, createThread, pending, threadCreated }
}
