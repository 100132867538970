import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import React from "react"
import styled from "styled-components"
import replyIcon from "../../../images/icons/reply.svg"
import userPlaceholder from "../../../images/user-placeholder.png"
import { AppFunctionComponent } from "../../../types"
import { listStyles } from "../../common/general-styles.component"
import { Comment, CommentReply } from "./blog.interface"

dayjs.extend(relativeTime)

const createAvatar = (avatar: string) => {
  return avatar.replace("{size}", "50")
}

interface Props {
  comment: Comment
  reply?: CommentReply
}

const CommentContainer = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 40px;

  display: flex;
  position: relative;
  box-sizing: border-box;

  box-shadow: 0 0 32px 0 rgba(46, 53, 57, 0.08);
  &:last-of-type {
    margin-bottom: 0px;
  }
`

const CommentHeader = styled.div`
  top: 16px;
  right: 25px;
  position: absolute;
  div {
    color: black;
    font-weight: 200;
    font-size: ${13 / 16}rem;

    display: flex;
    align-self: auto;
  }
`

const CommentContent = styled.div`
  display: flex;
`

const CommentAuthor = styled.div`
  flex-basis: 10%;
`

const CommentArticle = styled.div`
  flex-basis: 90%;
  padding: 15px 0 0 10px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 15px 70px 0 20px;
  }

  a {
    overflow-wrap: anywhere;
  }
  article,
  strong {
    margin-top: 20px;

    font-weight: 500;
    font-size: ${15 / 16}rem;
  }
  ul {
    ${listStyles}
  }
  blockquote {
    padding: 20px;
    margin: 20px 0;

    background-color: #f5f5f5;
    border-left: 2px solid #d3e4f0;
  }
  img {
    height: auto;
    width: auto;
    max-width: 100%;
    line-height: 1.2;
    margin-top: 5px;
    display: block;
    vertical-align: middle;
    &.emoji {
      max-width: 20px;
      margin-top: -3px;
    }
  }

  table {
    max-width: 560px;
    display: block;
    overflow-x: auto;

    @media (max-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
      max-width: 215px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.mobileSmall}px) {
      max-width: 160px;
    }
  }
  table,
  td,
  th {
    @media (max-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
      display: block;
      a {
        overflow-wrap: normal;
      }
    }
  }
`

const CommentAuthorAvatar = styled.img<{ size: number }>`
  margin-right: 10px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`

const ReplyContainer = styled.div`
  margin-right: 20px;
  ${CommentAuthorAvatar} {
    margin: -1px 5px 0;
  }
`

const ReplyIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`

const BlogComment: AppFunctionComponent<Props> = ({ comment, reply }) => {
  const { avatar_template, username, created_at, cooked } = comment
  return (
    <CommentContainer>
      <CommentHeader>
        <div>
          {reply && reply.username.length > 0 && (
            <ReplyContainer>
              <ReplyIcon src={replyIcon} alt="Reply" />{" "}
              <CommentAuthorAvatar
                size={21}
                src={
                  process.env.GATSBY_COMMUNITY_URL +
                  createAvatar(reply.avatar_template)
                }
                alt=""
              />{" "}
              {reply.username}
            </ReplyContainer>
          )}
          <div>{dayjs().from(dayjs(created_at), true)} ago</div>
        </div>
      </CommentHeader>
      <CommentContent>
        <CommentAuthor>
          <CommentAuthorAvatar
            size={50}
            src={
              process.env.GATSBY_COMMUNITY_URL +
              (comment.avatar_template
                ? createAvatar(avatar_template)
                : userPlaceholder)
            }
            alt=""
          />
        </CommentAuthor>
        <CommentArticle>
          <strong>{username}</strong>
          <article
            dangerouslySetInnerHTML={{
              __html: cooked.replace(
                "/u/",
                `${process.env.GATSBY_COMMUNITY_URL}/u/`
              ),
            }}
          />
        </CommentArticle>
      </CommentContent>
    </CommentContainer>
  )
}

export default BlogComment
